import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./Exchange.module.sass";
import Main from "./Main";
//import Trades from "./Trades";
import Balance from "./Balance";
import Currency from "./Currency";
import Table from "./Table";
import Actions from "./Actions";
import Charts from "./Charts";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
//import { io } from 'socket.io-client';
import Successfully from "./Successfully";
import Modal from '../../components/Modal'
import { addNotification } from "../../components/Notification";
import requestHandler from "../../actions/httpClient";
import { userStatusCreator } from "../../actions/getUserStatus";
import ConfirmationModal from "./ConfirmationModal";
import { socket } from "../../socket";
import MarketScroller from "../../components/MarketScroller";

const navigation = ["Chart", "Order books", "Trades"];

const Exchange = () => {

  const dispatch = useDispatch();
  const { slug } = useParams();

  const [activeIndex, setActiveIndex] = useState(0);
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [marketLoading, setMarketLoading] = useState(false)
  const [cancelOrder, setCancelOrder] = useState([])
  const [socketData, setSocketData] = useState([])

  const [buyOrdersData, setBuyOrdersData] = useState([])
  const [sellOrdersData, setSellOrdersData] = useState([])

  const [marketTrades, setMarketTrades] = useState([])
  const [currentMarketDetails, setCurrentMarketDetails] = useState([])
  const [openOrders, setOpenOrders] = useState([])
  const [exchangeData, setExchangeData] = useState([])

  const [orderActionResponse, setOrderActionResponse] = useState([])
  const [allMarketsData, setAllMarketsData] = useState([]);
  const { userStatus } = useSelector((state) => { return state.getUserStatus });
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });
  const [currentMarketPrice, setCurrentMarketPrice] = useState();
  const [dataMarkets, setDataMarkets] = useState([]);
  const [cancelOrderLoading, setCancelOrderLoading] = useState(false);
  const [visibleTransfer, setVisibleTransfer] = useState(false);
  const [openPosition, setOpenPosition] = useState([]);
  const [openPositionCross, setOpenPositionCross] = useState([]);
  const [activeTab, setActiveTab] = useState('cross');
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [item, setItem] = useState();
  const [closeButtonStatus, setCloseButtonStatus] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const tradingType = urlParams.get('type');
  //var socket = io(process.env.REACT_APP_SOCKET_URL);
  const [closePositionLoading, setClosePositionLoading] = useState(false);
  const [leverage, setLeverage] = useState("");
  const [leverageState, setLeverageState] = useState({
    coinBalanceIsolatedMaxLeverage: null,
    currencyBalanceIsolatedMaxLeverage: null,
    coinBalanceCrossMaxLeverage: null,
    currencyBalanceCrossMaxLeverage: null,
  });
  const [crossMarginLeverage, setCrossMarginLeverage] = useState();
  const [isolatedMarginLeverage, setIsolatedMarginLeverage] = useState();
  const [crossMarginSellLeverage, setCrossMarginSellLeverage] = useState();
  const [isolatedMarginSellLeverage, setIsolatedMarginSellLeverage] = useState();
  const [marketOrderType, setMarketOrderType] = useState("buy");
  const [favoriteMarkets, setFavoriteMarkets] = useState(userStatus?.userFavMarkets?.length > 0 ? userStatus?.userFavMarkets : []);
  const [scrollerMarkets, setScrollerMarkets] = useState([]);

  useEffect(() => {
    if (userStatus?.length === 0) {
      dispatch(userStatusCreator())
    }
    // getRegion();
  }, []);

  useEffect(() => {
    if (dataMarkets?.length > 0) {
      setScrollerMarkets(dataMarkets);
    }
  }, [dataMarkets]);

  useEffect(() => {
    if (tradingType?.toLowerCase() === "cross") {
      setActiveTab("cross");
      socket.emit('userIdMargin', { userId: userStatus?.id });
      socket.connect();
      socket.on('open_position_cross_' + userStatus?.id, (data) => {
        // console.log(`open_position_cross_${userStatus?.id}`, data);
        setOpenPositionCross(data);
      });
      socket.on('open_position_isolated_' + userStatus?.id, (data) => {
        // console.log(`open_position_isolated_${userStatus?.id}`, data);
        setOpenPosition(data);
      });
      return () => {
        socket.off('open_position_cross_' + userStatus?.id);
        socket.off('open_position_isolated_' + userStatus?.id);
        socket.disconnect();
      };
    }
  }, [slug]);

  useEffect(() => {
    createOrdersData(slug);
    // setCloseButtonStatus(false);
    socket.on("connect", () => { });

    socket.connect();

    socket.on("international_markets_web", (data) => {
      const sortedMarkets = data[0]?.markets?.sort((a, b) => a.market_position - b.market_position);
      setDataMarkets(sortedMarkets);
    });

    socket.on('orderBook_' + slug.toLowerCase(), function (order) {
      sortOrdersData(order)
    });

    socket.on('orderBookPrice_' + slug.toLowerCase(), function (order) {
      socket.emit('userIdMargin', { userId: userStatus?.id });
      setCurrentMarketPrice(order);
    });

    return () => {
      socket.off('orderBook_' + slug.toLowerCase());
      socket.off('orderBookPrice_' + slug.toLowerCase());
      socket.off("international_markets_web");
      socket.disconnect();
    }
  }, [slug, orderActionResponse])

  useEffect(() => {
    createOrdersData(slug);
  }, [slug, orderActionResponse])

  const createOrdersData = async (slug) => {
    setCurrentMarketPrice();
    try {
      setLoading(true);
      const formData = {
        market_slug: slug,
        signature: localStorage.getItem("signature")
      }
      const ordersDataPayload = await requestHandler("getOpenOrders", "post", formData, 'jwt_token');
      setExchangeData(ordersDataPayload)
      if (ordersDataPayload) {
        setCrossMarginLeverage(ordersDataPayload?.data?.data?.currencyBalanceCrossMaxLeverage[ordersDataPayload?.data?.data?.currencyBalanceCrossMaxLeverage?.length - 1]);
        setIsolatedMarginLeverage(ordersDataPayload?.data?.data?.currencyBalanceIsolatedMaxLeverage[ordersDataPayload?.data?.data?.currencyBalanceIsolatedMaxLeverage?.length - 1]);
        setCrossMarginSellLeverage(ordersDataPayload?.data?.data?.coinBalanceCrossMaxLeverage[ordersDataPayload?.data?.data?.coinBalanceCrossMaxLeverage?.length - 1]);
        setIsolatedMarginSellLeverage(ordersDataPayload?.data?.data?.coinBalanceIsolatedMaxLeverage[ordersDataPayload?.data?.data?.coinBalanceIsolatedMaxLeverage?.length - 1]);
        setLeverageState({
          coinBalanceIsolatedMaxLeverage: ordersDataPayload?.data?.data?.coinBalanceIsolatedMaxLeverage,
          currencyBalanceIsolatedMaxLeverage: ordersDataPayload?.data?.data?.currencyBalanceIsolatedMaxLeverage,
          coinBalanceCrossMaxLeverage: ordersDataPayload?.data?.data?.coinBalanceCrossMaxLeverage,
          currencyBalanceCrossMaxLeverage: ordersDataPayload?.data?.data?.currencyBalanceCrossMaxLeverage,
        });
      }

      setMarketTrades(ordersDataPayload.data.data.market_trades)
      setOpenOrders(ordersDataPayload.data.data.open_order)
      setCurrentMarketDetails(ordersDataPayload.data.data.current_market_details)

      setBuyOrdersData(createOrderbook(ordersDataPayload.data.data.buy))
      setSellOrdersData(createOrderbook(ordersDataPayload.data.data.sell))

      setLoading(false);
    }
    catch (e) {
      setLoading(false);
    };
  }

  const createOrderbook = (orders) => {
    let createOrders = [];
    let tempData = [];
    for (let order of orders) {
      if (tempData.indexOf(parseFloat(order.fiatPrice)) < 0) {
        createOrders.push(order)
        tempData.push(parseFloat(order.fiatPrice));
      } else {
        let index = tempData.indexOf(parseFloat(order.fiatPrice));
        createOrders[index].remcrypto = parseFloat(order.remcrypto) + parseFloat(createOrders[index].remcrypto);
        createOrders[index].remfiat = parseFloat(order.remfiat) + parseFloat(createOrders[index].remfiat);
      }
    }
    return createOrders;
  }

  const sortOrdersData = (order) => {
    if (order && order.orderType === parseInt(process.env.REACT_APP_BUY_LIMIT_ORDER)) {
      if (order.orderAction === "new_order")
        setBuyOrdersData(buyOrdersData => createOrderbook([...buyOrdersData.filter(buyOrder => buyOrder.orderId !== order.orderId), order]));
      else
        setBuyOrdersData(buyOrdersData => createOrderbook([...buyOrdersData.filter(buyOrder => buyOrder.orderId !== order.orderId)]));
    } else {
      if (order.orderAction === "new_order")
        setSellOrdersData(sellOrdersData => createOrderbook([...sellOrdersData.filter(sellOrder => sellOrder.orderId !== order.orderId), order]));
      else
        setSellOrdersData(sellOrdersData => createOrderbook([...sellOrdersData.filter(sellOrder => sellOrder.orderId !== order.orderId)]));
    }
  }

  // const getRegion = async () => {
  //   try {
  //     const regionPayload = await requestHandler("region", "post");
  //     for (let i = 0; i < regionPayload.data?.data.length; i++) {
  //       if (regionPayload?.data?.data[i].slug == process.env.REACT_APP_INTERNATIONAL_MARKETS) {
  //         getData(regionPayload?.data?.data[i]?.id);
  //       }
  //     }

  //   }
  //   catch (e) {
  //   };
  // };

  // const getData = async (id) => {
  //   try {
  //     setMarketLoading(true);
  //     let data = {
  //       type: "allMarkets",
  //       region: id,
  //       signature: localStorage.getItem('signature')
  //     };
  //     const marketsDataPayload = await requestHandler("get_markets", "post", data, "jwt_token");
  //     // console.log("marketsDataPayload", marketsDataPayload);
  //     // const sortedMarkets = marketsDataPayload?.data?.data[0]?.markets?.sort((a, b) => a.market_position - b.market_position);
  //     // console.log("sortedMarkets", sortedMarkets);
  //     // setAllMarketsData(marketsDataPayload?.data?.data);
  //     // setMarketLoading(false);
  //     // setDataMarkets(sortedMarkets);
  //   }
  //   catch (e) {
  //     setMarketLoading(false);
  //   };
  // }

  const orderHandler = (x) => {
    setCancelOrder(x);
    setVisible(true)
  }

  const clearSocketData = () => {
    setSocketData(null)
  }

  const orderResponse = (response) => {
    setOrderActionResponse(response)
  }

  const closeOpenPositionHandler = async (orderId) => {
    setClosePositionLoading(true);
    let data = {
      orderId: (orderId),
      signature: localStorage.getItem("signature"),
    };
    try {
      const closeOpenPositionPayload = await requestHandler("close_position", "post", data, "jwt_token");
      setClosePositionLoading(false);
      if (closeOpenPositionPayload?.status === 200) {
        addNotification({
          title: "Success",
          message: closeOpenPositionPayload?.data?.message[0]?.msg,
          type: "success",
        });
        socket.emit('userIdMargin', { userId: userStatus?.id });
        setConfirmationModal(false);
        await createOrdersData(slug);
        setCloseButtonStatus(false);
      }
    }
    catch (e) {
      setClosePositionLoading(false);
      addNotification({
        title: "Error",
        message: "Something went wrong, please try again",
        type: "danger",
      });
    };
  };

  return (
    <div className={styles.exchange}>
      <div className={styles.marketScroll}>
        {scrollerMarkets?.length > 0 && (
          <MarketScroller markets={scrollerMarkets} />
        )}
      </div>
      <div className={cn("containerEx", styles.containerEx)}>
        <Main currentMarketDetails={currentMarketDetails} currentMarketPrice={currentMarketPrice} />
        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
        {isTablet ? (
          <>
            <Actions
              userStatus={userStatus}
              tradingType={tradingType}
              currentMarketPrice={currentMarketPrice}
              currentMarketDetails={currentMarketDetails}
              exchangeData={exchangeData}
              orderResponse={orderResponse}
              slug={slug}
              orderActionResponse={orderActionResponse}
              setOrderActionResponse={setOrderActionResponse}
              leverage={leverage}
              setLeverage={setLeverage}
              leverageState={leverageState}
              crossMarginLeverage={crossMarginLeverage}
              setCrossMarginLeverage={setCrossMarginLeverage}
              isolatedMarginLeverage={isolatedMarginLeverage}
              setIsolatedMarginLeverage={setIsolatedMarginLeverage}
              marketOrderType={marketOrderType}
              setMarketOrderType={setMarketOrderType}
              crossMarginSellLeverage={crossMarginSellLeverage}
              setCrossMarginSellLeverage={setCrossMarginSellLeverage}
              isolatedMarginSellLeverage={isolatedMarginSellLeverage}
              setIsolatedMarginSellLeverage={setIsolatedMarginSellLeverage}
            />
            {activeIndex === 0 && (
              <div className={styles.box}>
                <Charts
                  currentMarketDetails={currentMarketDetails}
                  slugTV={slug}
                  slug={slug.replace("-", "").toUpperCase()}
                />
                <Table
                  openOrders={openOrders}
                  marketTrades={marketTrades}
                  orderHandler={orderHandler}
                />
              </div>
            )}
            {activeIndex === 1 && (
              <div className={styles.box}>
                <Balance
                  currentMarketPrice={currentMarketPrice}
                  buy={buyOrdersData}
                  sell={sellOrdersData}
                  currentMarketDetails={currentMarketDetails}
                  socketData={socketData}
                  clearSocketData={clearSocketData}
                />
              </div>
            )}
            {activeIndex === 2 && (
              <div className={styles.box}>
                <Currency
                  favoriteMarkets={favoriteMarkets}
                  setFavoriteMarkets={setFavoriteMarkets}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className={styles.row}>
              <div className={styles.col}>
                <Balance
                  currentMarketPrice={currentMarketPrice}
                  buy={buyOrdersData}
                  sell={sellOrdersData}
                  currentMarketDetails={currentMarketDetails}
                  socketData={socketData}
                  clearSocketData={clearSocketData}
                  loading={loading}
                  tradingType={tradingType}
                />
              </div>
              <div className={styles.col}>
                <Charts
                  currentMarketDetails={currentMarketDetails}
                  slugTV={slug}
                  slug={slug.replace("-", "").toUpperCase()}
                />
                <Actions
                  visibleTransfer={visibleTransfer}
                  setVisibleTransfer={setVisibleTransfer}
                  tradingType={tradingType}
                  currentMarketPrice={currentMarketPrice}
                  currentMarketDetails={currentMarketDetails}
                  exchangeData={exchangeData}
                  orderResponse={orderResponse}
                  userStatus={userStatus}
                  slug={slug}
                  orderActionResponse={orderActionResponse}
                  setOrderActionResponse={setOrderActionResponse}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  createOrdersData={createOrdersData}
                  leverage={leverage}
                  setLeverage={setLeverage}
                  leverageState={leverageState}
                  crossMarginLeverage={crossMarginLeverage}
                  setCrossMarginLeverage={setCrossMarginLeverage}
                  isolatedMarginLeverage={isolatedMarginLeverage}
                  setIsolatedMarginLeverage={setIsolatedMarginLeverage}
                  marketOrderType={marketOrderType}
                  setMarketOrderType={setMarketOrderType}
                  crossMarginSellLeverage={crossMarginSellLeverage}
                  setCrossMarginSellLeverage={setCrossMarginSellLeverage}
                  isolatedMarginSellLeverage={isolatedMarginSellLeverage}
                  setIsolatedMarginSellLeverage={setIsolatedMarginSellLeverage}
                />
              </div>
              <div className={styles.col}>
                <Currency
                  allMarketsData={allMarketsData}
                  marketLoading={marketLoading}
                  dataMarkets={dataMarkets}
                  setDataMarkets={setDataMarkets}
                  tradingType={tradingType}
                  leverage={leverage}
                  setLeverage={setLeverage}
                  favoriteMarkets={favoriteMarkets}
                  setFavoriteMarkets={setFavoriteMarkets}
                />
              </div>
            </div>
            <Table
              openOrders={openOrders}
              marketTrades={marketTrades}
              orderHandler={orderHandler}
              amountDecimal={currentMarketDetails[0]?.amountDecimalPrecision}
              priceDecimal={currentMarketDetails[0]?.priceDecimalPrecision}
              tradingType={tradingType}
              openPosition={openPosition}
              openPositionCross={openPositionCross}
              activeTab={activeTab}
              setConfirmationModal={setConfirmationModal}
              setItem={setItem}
              closeButtonStatus={closeButtonStatus}
              setOpenPositionCross={setOpenPositionCross}
              setOpenPosition={setOpenPosition}
            />
          </>
        )}
        <Modal
          visible={visible}
          onClose={() => setVisible(false)}
        >
          <Successfully
            item={cancelOrder}
            cancelOrderLoading={cancelOrderLoading}
            closeModal={() => setVisible(false)}
            cancelOrder={async (orderId) => {
              try {
                setCancelOrderLoading(true)
                const data = {
                  signature: localStorage.getItem("signature"),
                  orderId: orderId
                }
                const payload = await requestHandler('cancelOrder', 'post', data, 'jwt_token')
                if (payload.status === 200) {
                  setCancelOrderLoading(false)
                  addNotification({
                    title: 'Success',
                    message: 'Order cancelled successfully',
                    type: 'success'
                  })
                  dispatch(userStatusCreator())
                  setVisible(false)
                  setOrderActionResponse(payload)
                }
              }
              catch (error) {
                addNotification({
                  title: 'Error',
                  message: error?.data?.message[0]?.msg,
                  type: 'danger'
                })
                setVisible(false)
              }
            }}
          />
        </Modal>
        <Modal
          visible={confirmationModal}
          onClose={() => { setConfirmationModal(false) }}
        >
          <ConfirmationModal
            closeOpenPositionHandler={closeOpenPositionHandler}
            item={item}
            setConfirmationModal={setConfirmationModal}
            setCloseButtonStatus={setCloseButtonStatus}
            closePositionLoading={closePositionLoading}
          />
        </Modal>
      </div>
    </div>
  );
};

export default Exchange;
