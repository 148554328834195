import React, { useEffect, useState } from 'react';
import styles from "./ConvertCrypto.module.sass";
import cn from "classnames";
import { useDispatch, useSelector } from 'react-redux';
import { userStatusCreator } from '../../actions/getUserStatus';
import { getDigitsAfterDecimal } from "../../components/helper";
import LoaderScreen from '../../components/LoaderScreen';
import requestHandler from '../../actions/httpClient';
import { addNotification } from '../../components/Notification';
import Confirmation from "./Confirmation";
import Modal from '../../components/Modal';
import { Link, useNavigate } from 'react-router-dom';

const ConvertCrypto = () => {
    const [activeFAQ, setActiveFAQ] = useState(null);
    const { userStatus } = useSelector((state) => { return state.getUserStatus });
    const dispatch = useDispatch();
    const [coinData, setCoinData] = useState("");
    const [fromValue, setFromValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [toValue, setToValue] = useState("");
    const [visibleModal, setVisibleModal] = useState(false);
    const navigate = useNavigate();

    const faqData = [
        { title: 'How to use Bitdenex Convert?', description: 'Bitdenex Convert is a simple and efficient way to convert BDNX with zero fees. All you need to do is, enter the amount you want to exchange. Bitdenex will offer you the best possible exchange rate. If you are satisfied with the previewed exchange rate, select Confirm and the exchanged BDNX will be deposited into your spot account.' },
        { title: 'What is the difference between Bitdenex Convert and Spot Trading?', description: 'Bitdenex Convert uses a quoting model, which differs from the traditional order-matching model, resulting in no transaction fees or slippage. There is a price difference between the quoted price and the spot market price. In a typical conversion, the user first receives a quote by selecting the pair they wish to convert. Bitdenex Convert then provides a competitive price based on the current market conditions. If the user accepts the quote, the conversion will be executed immediately.' },
        { title: 'What coins are available for conversion on Bitdenex?', description: 'At the moment, you can only exchange the Bitdenex token (BDNX). In the near future, we will add more coins and tokens such as BTC, USDT, ETH, and other coins. You can simply select a crypto pair to receive a quote.' },
    ];

    useEffect(() => {
        if (!userStatus || userStatus.length === 0) {
            dispatch(userStatusCreator());
        }
        else {
            const filteredBalances = userStatus?.wallet?.balances
                ?.filter(x => ["usdt", "bdnx"].includes(x?.symbol?.toLowerCase()));

            const usdtBalance = filteredBalances?.find(x => x.symbol.toLowerCase() === "usdt");
            const bdnxBalance = filteredBalances?.find(x => x.symbol.toLowerCase() === "bdnx");

            setCoinData({ usdtBalance, bdnxBalance });
        }
    }, [userStatus]);

    useEffect(() => {
        getRegion();
    }, []);

    const getRegion = async () => {
        setLoading(true);
        try {
            const regionPayload = await requestHandler("region", "post");
            setLoading(false);
            for (let i = 0; i < regionPayload.data?.data.length; i++) {
                if (regionPayload?.data?.data[i].slug == process.env.REACT_APP_INTERNATIONAL_MARKETS) {
                    getData(regionPayload?.data?.data[i]?.id);
                }
            }
        }
        catch (e) {
            setLoading(false);
        };
    };

    const getData = async (id) => {
        setLoading(true);
        try {
            let data = {
                type: "allMarkets",
                region: id,
                signature: localStorage.getItem('signature')
            };
            const marketsDataPayload = await requestHandler("get_markets", "post", data, 'jwt_token');
            setLoading(false);
            const filteredBalances = marketsDataPayload?.data?.data[0]?.markets
                ?.filter(x => ["bdnx"].includes(x?.symbol?.toLowerCase()));

            const bdnxBalance = filteredBalances?.find(x => x.symbol.toLowerCase() === "bdnx");
            setToValue(bdnxBalance);
        }
        catch (e) {
            setLoading(false);
        };
    }

    const convertCoinHandler = async () => {
        setLoading(true);
        let data = {
            marketId: toValue.id,
            orderType: process.env.REACT_APP_BUY_MARKET_ORDER,
            fiat_spend: fromValue,
            signature: localStorage.getItem("signature"),
        }
        try {
            const convertCoinPayload = await requestHandler("convertCoin", "post", data, "jwt_token");
            setLoading(false);
            if (convertCoinPayload.status === 200) {
                addNotification({
                    title: "Success",
                    message: convertCoinPayload.data.message[0].msg,
                    type: "success",
                });
                dispatch(userStatusCreator());
                setFromValue("");
                setVisibleModal(false);
            }
        }
        catch (e) {
            setLoading(false);
            addNotification({
                title: "Error",
                message: "Something went wrong, please try again later",
                type: "danger",
            });
        };
    };

    const handleChange = (e) => {
        let newValue = e.target.value;

        if (parseFloat(newValue) > parseFloat(process.env.REACT_APP_CONVERT_CRYPTO_MAX_VALUE)) {
            newValue = parseFloat(process.env.REACT_APP_CONVERT_CRYPTO_MAX_VALUE);
        }

        if (parseFloat(newValue) < parseFloat(process.env.REACT_APP_CONVERT_CRYPTO_MIN_VALUE)) {
            newValue = parseFloat(process.env.REACT_APP_CONVERT_CRYPTO_MIN_VALUE);
        }

        setFromValue(newValue);
    };

    const toggleOpen = (index) => {
        setActiveFAQ(activeFAQ === index ? null : index);
    };

    return (
        <>
            {loading && <LoaderScreen />}
            <div className={cn("container", styles.container)}>
                <header className={styles.convertheader}>
                    {/* <h2 className={cn("h2", styles.converttitle)}>Convert</h2> */}
                </header>

                <div className={styles.convertbody}>
                    <div className={styles.lefttextarea}>
                        <h1 className={cn("h2", styles.title)}>Convert USDT to BDNX</h1>
                        <div className={styles.subtitle}>Convert Bitdenex Token with zero fees</div>
                        <p className={styles.des}>
                            Bitdenex Convert simplifies crypto conversion for users by eliminating
                            the need for multiple trades or frequent asset transfers between wallets
                            and exchanges. Bitdenex Convert provides a seamless way for users to
                            convert BDNX.
                        </p>
                    </div>

                    <section className={styles.rightsection}>
                        <div className={styles.convertheaderright}>
                            <div className={styles.headerleft}>
                                <h3 className={styles.headertitle}>Spot account</h3>
                                <span className={styles.feestatus}>0 FEE</span>
                            </div>
                            <div className={styles.headerright}>
                                <div className={styles.converthistory}>
                                    <button className={cn('button-small')} onClick={() => navigate('/convert-crypto-list')}>
                                        Convert history
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={styles.inputcontainer}>
                            <div className={styles.inputgroup}>
                                <label>From</label>
                                <div className={styles.inputbox}>
                                    <div className={styles.coinselect}>
                                        <img src={`${process.env.REACT_APP_IMAGE_URL}/${coinData?.usdtBalance?.image}`} alt="eur" />
                                        <span>USDT</span>
                                    </div>
                                    <input
                                        type="text"
                                        placeholder={`Enter amount between ${process.env.REACT_APP_CONVERT_CRYPTO_MIN_VALUE} and ${process.env.REACT_APP_CONVERT_CRYPTO_MAX_VALUE}`}
                                        value={fromValue}
                                        onChange={(e) => handleChange(e)}
                                        maxLength={8}
                                    />
                                </div>
                                <div className={styles.balanceinfo}>
                                    <span>Balance: {coinData === null ? "0" : getDigitsAfterDecimal(coinData?.usdtBalance?.balance, 2)}</span>
                                    <span className={styles.maxbtn} onClick={() => { setFromValue(coinData?.usdtBalance?.balance); }}>Max</span>
                                </div>
                            </div>

                            <div className={styles.inputgroup}>
                                <label>To(Approx)</label>
                                <div className={styles.inputbox}>
                                    <div className={styles.coinselect}>
                                        <img src={`${process.env.REACT_APP_IMAGE_URL}/${coinData?.bdnxBalance?.image}`} alt="eur" />
                                        <span>BDNX</span>
                                    </div>
                                    <input
                                        type="text"
                                        placeholder={fromValue > 0 ? getDigitsAfterDecimal(parseFloat(fromValue) / parseFloat(toValue?.currentMarketPrice), toValue?.amountDecimalPrecision) : 0}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <button
                                className={fromValue > 0 ? styles.convertbutton : styles.disabledButton}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setVisibleModal(true);
                                }}
                            >
                                Preview
                            </button>
                        </div>
                    </section>
                </div>

                {/* <div className={styles.faqcontent}>
                    <div className={styles.commonfaqbox}>
                        <div className={styles.commonfaqtitle}>
                            <h2>FAQ</h2>
                        </div>

                        <div className={styles.commonfaqlist}>
                            <div className={styles.commonfaqq} onClick={() => toggleFAQ(1)}>
                                <h3>1. How to use Bitdenex Convert?</h3>
                            </div>
                            {activeFAQ === 1 && (
                                <div className={styles.commonfaqlisttext}>
                                    <div className={styles.list}>
                                        <div className={styles.text}>
                                            Bitdenex Cash Conversion is a simple and efficient way to convert crypto with zero transaction fees...
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className={styles.commonfaqlist}>
                            <div className={styles.commonfaqq} onClick={() => toggleFAQ(2)}>
                                <h3>2. What is the difference between Bitdenex Convert and Spot Trading?</h3>
                            </div>
                            {activeFAQ === 2 && (
                                <div className={styles.commonfaqlisttext}>
                                    <div className={styles.list}>
                                        <div className={styles.text}>
                                            Bitdenex Convert uses a quoting model, which differs from the traditional order-matching model...
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className={styles.commonfaqlist}>
                            <div className={styles.commonfaqq} onClick={() => toggleFAQ(3)}>
                                <h3>3. What coins are available for conversion on Bitdenex?</h3>
                            </div>
                            {activeFAQ === 3 && (
                                <div className={styles.commonfaqlisttext}>
                                    <div className={styles.list}>
                                        <div className={styles.text}>
                                            You can exchange most coins listed on Bitdenex, including BTC, USDT, ETH, and other coins...
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div> */}
                <div className={styles.faqcontainer}>
                    {faqData?.map((item, index) => (
                        <div key={index} className={`${styles.faqitem} ${activeFAQ === index ? styles.open : ''}`}>
                            <div className={styles.faqtitle} onClick={() => toggleOpen(index)}>
                                <h3>{item?.title}</h3>
                                <span className={styles.faqicon}>{activeFAQ === index ? '−' : '+'}</span>
                            </div>
                            <div className={`${styles.faqdescription} ${activeFAQ === index ? styles.visible : ''}`}>
                                <p>{item?.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Modal
                visible={visibleModal}
                onClose={() => { setVisibleModal(false); }}
            >
                <Confirmation
                    fromValue={fromValue}
                    toValue={toValue}
                    coinData={coinData}
                    convertCoinHandler={convertCoinHandler}
                />
            </Modal>
        </>
    );
};

export default ConvertCrypto;
